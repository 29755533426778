import React, { useState, useEffect } from "react";
import COLORS from "../../Style/colors";
import { Spin, Tag, Typography } from "antd";
import CustomButton from "../../Components/CustomButton";
import { getWhatsappQr, checkAuth, resetWhatsaapQr } from "../../API/fetch";
import QrCreator from "qr-creator";
import openNotification from "../../Components/Notifications";

const { Title, Text } = Typography;
function SettingWhatsaap() {
  const [loading, setLoading] = useState(false);
  const [qrText, setQrText] = useState("");
  const [authStatus, setAuthStatus] = useState("failed");

  useEffect(() => {
    checkWhatsappAuth();
  }, []);

  const checkWhatsappAuth = async () => {
    try {
      setLoading(true);
      const res = await checkAuth();
      setAuthStatus(res.data.status);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleWhatsapp = async () => {
    try {
      setLoading(true);
      const res = await getWhatsappQr();
      if (res?.data?.status === "Authenticated") {
        setAuthStatus("success");
        setLoading(false);
        openNotification({
          title: "ناجح",
          description: "تم ربط الواتساب بنجاح",
          type: "success",
          rtl: true,
        });
        return;
      }

      setQrText(res.data?.qrText);
      setLoading(false);
      const qrs = document.getElementById("qr");
      while (qrs.firstChild) {
        qrs.removeChild(qrs.firstChild);
      }
      const qr = QrCreator.render(
        {
          text: res.data.qrText,
          radius: 0.5, // 0.0 to 0.5
          ecLevel: "H", // L, M, Q, H
          fill: COLORS.primary, // foreground color
          background: null, // color or null for transparent
          size: 256, // in pixels
        },
        document.querySelector("#qr")
      );
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const changeWhatsaap = async () => {
    try {
      setLoading(true);
      const res = await resetWhatsaapQr();
      const qrs = document.getElementById("qr");
      while (qrs.firstChild) {
        qrs.removeChild(qrs.firstChild);
      }
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Title level={3}>إعدادات</Title>
      </div>

      <div
        style={{
          backgroundColor: COLORS.white,
          marginTop: 10,
          width: "92%",
          minHeight: "70vh",
          marginRight: "auto",
          marginLeft: "auto",
          borderRadius: 10,
          padding: 20,
        }}
        className='light-shadow'
      >
        <Spin spinning={loading}>
          <Text className='my-font'>
            حالة اتصال الواتساب هي :{" "}
            <Tag color={authStatus === "failed" ? "red" : "green"}>
              {authStatus === "failed" ? "فشل" : "ناجح"}
            </Tag>
          </Text>
          <br />
          <CustomButton
            type='primary'
            text={"إعادة ربط الواتساب"}
            className='border-8 mx-2 my-2'
            onClick={handleWhatsapp}
          />
          <CustomButton
            type='danger'
            text={"تغيير رقم الواتساب"}
            className='border-8 mx-2 my-2'
            onClick={changeWhatsaap}
          />
          <div id='qr' />
        </Spin>
      </div>
    </div>
  );
}

export default SettingWhatsaap;
