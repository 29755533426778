import React, { useState, useRef, useEffect } from "react";
import { Layout, Image, Typography } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

import LOGO from "../../assets/images/nada.png";
import ICON from "../../assets/images/nada.png";
import SuperUserSide from "./SuperUserSide";
import buildNumber from "../../buildNumber";

const { Header, Content, Footer, Sider } = Layout;
const { Text } = Typography;
const Sidebar = ({ children, component }) => {
  const { rtl, isBigScreen } = useSelector((state) => state.settings);
  const { role, user } = useSelector((state) => state.auth);
  const sideRef = useRef();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [sideBarWidth, setSideBarWidth] = useState(200);

  useEffect(() => {
    if (sideRef.current) {
      setSideBarWidth(sideRef.current.offsetWidth);
    }
  }, [sideRef?.current?.offsetWidth]);

  useEffect(() => {
    if (isCollapsed) {
      if (isBigScreen) {
        setSideBarWidth(60);
      } else {
        setSideBarWidth(0);
      }
    } else {
      setSideBarWidth(230);
    }
  }, [isCollapsed, isBigScreen]);
  // console.log(waiter)

  const toggleSideBar = () => {
    if (!isCollapsed && !isBigScreen) {
      setIsCollapsed(true);
    }
  };

  return (
    <Layout>
      <Header
        style={{
          paddingLeft: rtl ? 5 : sideBarWidth,
          paddingRight: rtl ? sideBarWidth : 5,
          position: "absolute",
          width: "100vw",
        }}
      ></Header>
      <Sider
        breakpoint='lg'
        collapsedWidth={isBigScreen ? "60" : "0"}
        className='light-shadow'
        collapsible
        width={sideBarWidth}
        trigger={null}
        collapsed={isCollapsed}
        onBreakpoint={(collapsed) => {
          setIsCollapsed(collapsed);
        }}
        style={{
          zIndex: 99,
        }}
      >
        {sideBarWidth > 100 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Image
              src={LOGO}
              preview={false}
              width={60}
              style={{ objectFit: "contain", borderRadius: 7 }}
            />
            <Text
              className='my-font'
              style={{ textAlign: "center" }}
            >{`V${buildNumber.buildMajor}.${buildNumber.buildMinor}.${buildNumber.buildRevision}`}</Text>
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Image
              src={ICON}
              preview={false}
              width={60}
              style={{ marginLeft: rtl ? 0 : 5, marginRight: rtl ? 5 : 0,  borderRadius: 7 }}
            />
            <Text
              className='my-font'
              style={{ textAlign: "center" }}
            >{`V${buildNumber.buildMajor}.${buildNumber.buildMinor}.${buildNumber.buildRevision}`}</Text>
          </div>
        )}
        <SuperUserSide toggleSideBar={toggleSideBar} />
      </Sider>
      <Layout
        className='site-layout'
        style={{
          marginLeft: rtl ? 0 : sideBarWidth,
          marginRight: rtl ? sideBarWidth : 0,
          marginTop: 60,
          minHeight: "95vh",
        }}
      >
        <div className='toggle-bar'>
          {isCollapsed ? (
            rtl ? (
              <MenuFoldOutlined
                style={{ fontSize: 20 }}
                onClick={() => setIsCollapsed(!isCollapsed)}
              />
            ) : (
              <MenuUnfoldOutlined
                style={{ fontSize: 20 }}
                onClick={() => setIsCollapsed(!isCollapsed)}
              />
            )
          ) : rtl ? (
            <MenuUnfoldOutlined
              style={{ fontSize: 20 }}
              onClick={() => setIsCollapsed(!isCollapsed)}
            />
          ) : (
            <MenuFoldOutlined
              style={{ fontSize: 20 }}
              onClick={() => setIsCollapsed(!isCollapsed)}
            />
          )}
        </div>
        <Content
          style={{
            margin: "16px 16px 0",
            overflow: "initial",
          }}
        >
          {component}
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Made by iwaiter team ©{new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Sidebar;
