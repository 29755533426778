import axios from "axios";
import { api } from "./index";

export const signin = (data) => {
  return api.post(`/sign-in`, data);
};

export const postLogOut = () => {
  return api({
    method: "DELETE",
    url: `/sign-out`,
  });
};

export const getStudents = () => {
  return api.get(`/students`);
};
export const addStudents = (data) => {
  return api.post(`/students`, data);
};

export const getWhatsappQr = () => {
  return api.get(`/getqr`);
};

export const checkAuth = () => {
  return api.get(`/checkauth`);
};

export const sendMessages = (data) => {
  return api.post(`/messages-numbers`, data, {timeout: 5000000});
};

export const sendMessageWithImage = (data) => {
  return api.post(`/messages-numbers-with-image`, data, {timeout: 5000000});
};

export const resetWhatsaapQr = () => {
  return api.get(`/resetqr`, {timeout: 1000*60*10});
};

export const createStudent = (data) => {
  return api.post(`/students`, data);
};

export const updateStudent = (id, data) => {
  return api.patch(`/students/${id}`, data);
};

export const getAdminData = () => {
  return api.get(`/admin-data`);
};

export const getRestaurants = () => {
  return api.get(`/admin-restaurants`);
};
export const getUsers = () => {
  return api.get(`/admin-users`);
};
export const getIntegrations = () => {
  return api.get(`/admin-integrations`);
};
export const getRefers = () => {
  return api.get(`/admin-refers`);
};

export const patchRefer = (id, data) => {
  return api.patch(`/refers/${id}`, data);
};

export const postRefer = (data) => {
  return api.post(`/refers`, data);
};

// export const sendMessage = (data) => {
//   return api.post(`/send-message`, data);
// };

export const getPayments = (query) => {
  return api.get(`/restaurants-wallets${query}`, {timeout: 500000});
};

export const getRestaurant = (id) => {
  return api.get(`/admin-restaurants/${id}`);
};

export const updateRestaurant = (id, data) => {
  return api.patch(`/admin-restaurants/${id}`, data);
};

export const deleteRestaurant = (id) => {
  return api.delete(`/delete-restaurant/${id}`);
};

export const postPrivateWallet = (data) => {
  return api.post(`/create-wallet`, data);
};
export const cancelPrivateWallet = (data) => {
  return api.patch(`/cancel-wallet`, data);
};

export const createDefaultWallet = (data) => {
  return api.patch(`/create-default-wallet`, data);
};

export const getIntegration = (id) => {
  return api.get(`/admin-integrations/${id}`, {
    timeout: 100000
  });
};

export const addBranch = (id, data) => {
  return api.post(`/admin-restaurants/${id}/branches`, data);
};

export const updateIntegration = (id, data) => {
  return api.patch(`/integrations/${id}`, data);
};

export const updateIntegrationForFood = (id, data) => {
  return api.patch(`/admin-integrations-food/${id}`, data);
};

export const createTablesLikeFoodics = (data) => {
  return api.post(`/create-tables-foodics`, data);
};

export const createFoodicsMenu = (data) => {
  return api.post(`/create-menu-foodics`, data);
};

export const createTransaction = (data) => {
  return api.post(`/paymentTransactions`, data);
};

export const removeIntegration = (id) => {
  return api.delete(`/integrations/${id}`);
};

export const getTotalToNowAPI = () => {
  return api.get(`/admin-total-to-now`);
};

export const getSettlements = (query) => {
  return api.get(`/payment-settlements/${query}`);
};

export const updatePaymentSettlements = (data) => {
  return api.patch(`/update-payment-settlements`, data);
};

export const getAllEmails = () => {
  return api.get(`/admin-users-emails`);
};

export const sendWhatsAppMessage = (data) => {
  return axios({
    method: "POST",
    url: "https://api.game.iwaiter.net/v1/send-whats",
    headers: {
      Authorization: `Bearer 9410348A3249B87446B2E0FCD63A8E47`,
    },
    data: {
      message: data.message,
      customerPhone: data.phone,
    },
  });
};

export const getPendingPayments = (query) => {
  return api.get(`/pending-payments/${query}`);
};
export const getSettlementInfoPayments = (query) => {
  return api.get(`/settlementInfo${query}`);
};
export const getSettlementByTransactionNumber = (number) => {
  return api.get(`/settlementInfo/${number}`);
};

export const verifyPayment = (id) => {
  return api.get(`/verify-payment/${id}`);
};
export const verifyPrivatePayment = (id, restaurantId) => {
  return api.get(`/verify-payment/${id}?restaurantId=${restaurantId}`);
};

export const verifyPaymentReservation = (id, reservationId) => {
  return api.get(`/verify-payment/${id}?reservationId=${reservationId}`);
};
export const verifyPrivatePaymentReservation = (
  id,
  restaurantId,
  reservationId
) => {
  return api.get(
    `/verify-payment/${id}?restaurantId=${restaurantId}&reservationId=${reservationId}`
  );
};

export const deleteUserFromDB = (id) => {
  return api.delete(`/admin-users/${id}`);
};

// export const sendMessageWithImage = (data) => {
//   return axios({
//     method: "POST",
//     url: "https://api.game.iwaiter.net/v1/send-report",
//     headers: {
//         Authorization: `Bearer 9410348A3249B87446B2E0FCD63A8E47`,
//     },
//     data,
//     });
// };

// export const getBankList = () => {
//   return api.get(`/my-fatoorah-banks`);
// };
export const getSuppliersPaymentInfo = () => {
  return api.get(`/my-fatoorah-payments`);
}

export const createMyFatoorah = (data) => {
  return api.post('/create-my-fatoorah', data)
}

export const getMyFatoorahAccount = (code) => {
  return api.get(`/my-fatoorah-account/${code}`)
}

export const addService = (data) => {
  return api.post('/services', data)
}

export const getBanks = () => {
  return api.get(`/my-fatoorah-banks`)
}

export const editWalletRestaurant = (data, id) =>{
  return api.patch(`/wallets/${id}`, data)
}
export const getAppRestaurants = () => {
  return api.get(`/app-restaurants`);
};

export const editAppRestaurantInfo = (appId, data) => {
  return api.patch(`/app-restaurants/${appId}`, data);
}

export const getUsersApplication = () => {
  return api.get(`/users-application`);
}

export const sendApplicationNotification = (data) => {
  return api.post(`/send-application-notification`, data);
}

export const updateService = (id, data) => {
  return api.patch(`/services/${id}`, data);
}

export const addRestaurantToTheApp = (id) => {
  return api.post(`/app-restaurants/${id}`);
}

export const updateBranchLocation = (id, data) => {
  return api.patch(`/branches-location/${id}`, data);
}

export const sendEmails = (data) => {
  return api.post(`/send-emails`, data);
}