import React from "react";
import { Menu, Typography } from "antd";

import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { logOutAction } from "../../redux/reducers/authReducer";
import { useDispatch } from "react-redux";
import {
  IoHomeOutline,
  IoLogOut,
  IoPeopleOutline,
  IoChatboxEllipsesOutline,
  IoNotificationsOutline,
} from "react-icons/io5";
import { SettingOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { MdDoNotDisturbOnTotalSilence, MdWatchLater } from "react-icons/md";

const { Text } = Typography;
const SuperUserSide = ({ toggleSideBar }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  return (
    <Menu theme='light' mode='inline' defaultSelectedKeys={["1"]}>
      <Menu.Item
        key='home'
        icon={<IoHomeOutline size={20} />}
        onClick={() => {
          // toggleSideBar();
        }}
      >
        <Link to='/'>{intl.formatMessage({ id: "home" })}</Link>
      </Menu.Item>


      <Menu.Item
        onClick={() => {
          // toggleSideBar();
        }}
        key='whtsapp'
        icon={<WhatsAppOutlined size={20} />}
      >
        <Link to='/whtsapp'>{intl.formatMessage({ id: "whtsapp" })}</Link>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          // toggleSideBar();
        }}
        key='settings'
        icon={<SettingOutlined size={20} />}
      >
        <Link to='/setting'>إعداد الواتساب</Link>
      </Menu.Item>

      <Menu.Item
        key='signOut'
        onClick={() => {
          dispatch(logOutAction());
          // toggleSideBar();
        }}
        icon={<IoLogOut size={20} />}
      >
        <Text className='my-font'>{intl.formatMessage({ id: "signout" })}</Text>
      </Menu.Item>
    </Menu>
  );
};

export default SuperUserSide;
