import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  students: [],
};

export const restaurantsSlice = createSlice({
  name: "students",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setStudents: (state, action) => {
      state.students = action.payload;
    },
  },
});

export const {
  setStudents: setStudentsAction,
} = restaurantsSlice.actions;

export default restaurantsSlice.reducer;
