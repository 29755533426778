import { createSlice } from "@reduxjs/toolkit";
import { arrAdd, arrUpdateByKeyValue } from "../../../helpers/array";

const initialState = {
  restaurants: [],
  users: [],
  integrations: [],
  refers: [],
  payments: [],
  appRestaurants: [],
};

export const restaurantsSlice = createSlice({
  name: "restaurants",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setRestaurants: (state, action) => {
      state.restaurants = action.payload;
    },
    setAppRestaurants: (state, action) => {
      state.appRestaurants = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    serIntegrations: (state, action) => {
      state.integrations = action.payload;
    },
    setRefers: (state, action) => {
      state.refers = action.payload;
    },
    editRefer: (state, action) => {
      state.refers = arrUpdateByKeyValue({arr: state.refers, key: "_id", value: action.payload._id, newElement: action.payload})
    },
    addRefer: (state, action) => {
      state.refers = arrAdd({arr: state.refers, newElement: action.payload})
    },
    setPayments: (state, action) => {
      state.payments = action.payload;
    },
    editPayment: (state, action) => {
      state.payments = arrUpdateByKeyValue({arr: state.payments, key: "_id", value: action.payload._id, newElement: action.payload})
    },
    
    
  },
});

export const {
  setRestaurants: setRestaurantsAction,
  setUsers: setUsersAction,
  serIntegrations: setSerIntegrationsAction,
  setRefers: setRefersAction,
  editRefer: editReferAction,
  addRefer: addReferAction,
  setPayments: setPaymentsAction,
  editPayment: editPaymentAction,
  setAppRestaurants: setAppRestaurantsAction,
} = restaurantsSlice.actions;

export default restaurantsSlice.reducer;
