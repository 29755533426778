import {persistCombineReducers} from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import settingsReducer from './settingsReducer';
import authReducer from './authReducer';
import restaurantsReducer from './restaurantsReducer';
import settlementReducer from './settlementReducer';
import studentsReducer from './studentsReducer';


const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['settings', 'auth', 'restaurants','settlement' , 'students'], 
  transforms: [], // TODO: secure some data?
};
const reducers = persistCombineReducers(persistConfig, {
    settings: settingsReducer,
    auth: authReducer,
    restaurants: restaurantsReducer,
    settlement: settlementReducer,
    students: studentsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logOut') { // check for action type 
    state = {}; // reset state
    storage.removeItem('persist:root');
  }
  return reducers(state, action);
};


export default rootReducer;
